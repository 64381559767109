nav {
  /* background: rgba(0, 0, 0, 0.3); */
  background: #ffd1dc;
  width: max-content;
  display: block;
  padding: 0.7rem 1.7rem;
  z-index: 2;
  position: fixed;
  /* left: 40%; */
  transform: translateX(-50%);
  bottom: 1rem;
  display: flex;
  gap: 0.8rem;
  border-radius: 3rem;
  backdrop-filter: blur(15px);
}

nav a {
  background: transparent;
  padding: 0.9rem;
  border-radius: 50%;
  display: flex;
  /* color: var(--color-light); */
  color: var(--color-hot-pink);
  font-size: 1.1rem;
}

nav a:hover {
  background: rgba(0, 0, 0, 0.3);
}

nav a.active {
  /* background: var(--color-bg); */
  background: #e75480;
  color: var(--color-white);
}
