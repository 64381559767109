input,
textarea {
  width: 100%;
  padding: 1.5rem;
  border-radius: 0.5rem;
  background: transparent;
  border: 2px solid var(--color-hot-pink); /** var(--color-primary-variant)*/
  resize: none;
  color: var(--color-white);
}

input::-webkit-input-placeholder {
  color: var(--color-light);
}
input:-moz-placeholder {
  color: var(--color-light);
}

textarea::-webkit-input-placeholder {
  color: var(--color-light);
}
textarea:-moz-placeholder {
  color: var(--color-light);
}
